:root {
  --gray-500: rgba(37, 43, 55, 1);
  --gray-400: rgba(67, 77, 99, 1);
  --gray-300: rgba(101, 103, 107, 1)
}

.leading-\[18px\] {
  line-height: 18px;
}

.social__ad img {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.btn__select {
  background-color: #F0F0F0;
  padding: 8px 16px;
  border-radius: 5px;
}

.post__title,
.post__title::placeholder {
  font-size: 16px;
  line-height: 18.4px;
}


.post__files {
  max-height: 232px;
  border-color: #CCCCCC;
  overflow-y: auto;
}

.post__files-row {
  display: flex;
}

.post__files-image {
  position: relative;
  height: 186px;
  width: 186px;
}

/* .post__files-row .post__files-image:not(:last-child) {
  margin-right: 38px;
} */

.post__files-image img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.post__files-file {
  position: relative;
  height: 74px;
  background-color: #F1F1F1;
  border: 0.2px solid #CCCCCC;
  box-sizing: border-box;
  border-radius: 10px;
  margin-top: 19px;
}

.post__files-file.upload {
  background-color: #C8E2FD;
}

.post__files-file .close-btn,
.post__files-image .close-btn {
  border: 0.2px solid #CCCCCC;
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.post__files-file .btn-download {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0.5px solid #CCCCCC;
  box-sizing: border-box;
  border-radius: 25px;
  width: 50px;
  height: 50px;
  background-color: white;
}

.post__files-file.loading {
  background-color: #C8E2FD;
}

.post__files-file.loading .btn-download {
  background-color: transparent;
  border: 2px solid #0060E1;
}

.post__classify-type {
  display: none;
}

.post__classify-type+div {
  border: 1px solid #798295;
  box-sizing: border-box;
  width: 24px;
  height: 24px;
  border-radius: 24px;
}

.post__classify-type+div>img {
  opacity: 0;
}

.post__classify-type:checked+div>img {
  opacity: 1;
}

.post__comunity-select .active {
  background-color: #E9EAEF;
}

.post__department-filter {
  background-color: #F5F6F9;
  padding: 8px 15px;
}

.post__department-choice:hover {
  background-color: #E9EAEF;
}

.text-24px {
  font-size: 24px;
  line-height: 28px;
}

.text-black {
  color: black;
}

.line-clamp {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  /* -webkit-line-clamp: 2; */
  -webkit-box-orient: vertical;
}

.button-newpost {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 3.625rem;
  height: 3.625rem;
  border-radius: 999px;
  background-color: rgba(0, 96, 225, 1);
  position: fixed;
  /* right: calc(calc(100% - 700px) / 2); */
  right: 5.875rem;
  bottom: 1rem;
}

@media (min-width: 1536px) {
  .button-newpost {
    right: calc(calc(100% - 700px) / 2);
  }
}

/* _________________ POST PAGE ______________ */

.page__post {
  display: flex;
  flex-direction: column;
  color: black;
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;
  z-index: 0;
  background-color: #F0F0F0;
}

.page__post-container {
  max-width: 1100px;
  background-color: white;
  border: 0.5px solid #CCCCCC;
  box-sizing: border-box;
  width: 100%;
  margin: 0 auto;
  flex: 1;
}

.page__post-header {
  border-bottom: 0.5px solid #CCCCCC;
  padding: 16px 24px 16px 24px;
}

.page__post-title {
  font-weight: bold;
  font-size: 30px;
  line-height: 34px;
}

.post__author {
  display: flex;
}

.post__author-avt {
  width: 58px;
  height: 58px;
  border-radius: 58px;
  overflow: hidden;
  margin-right: 10px;
}

.post__author-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.post__follow {
  padding: 9px 24px 9px 24px;
  font-size: 16px;
  line-height: 18.4px;
  border: 0.5px solid #CCCCCC;
  border-radius: 5px;
}

.post__content {
  padding: 12px 0;
}

.post__content>p {
  font-size: 20px;
  line-height: 23px;
  text-align: justify;
  padding: 0 24px;
}

.post__content-images {
  display: flex;
  flex-wrap: wrap;
  padding: 0 24px;
}

.post__content-images>div {
  margin-right: 12px;
  width: 200px;
  height: 200px;
}

.post__content-images>div img {
  object-fit: cover;
}

.post__suggest {
  width: 301px;
  font-size: 13px;
  line-height: 14.95px;
  padding: 0 14px 0 22px;
}

.post__suggest>p {
  font-size: 18px;
  line-height: 20.7px;
  margin-top: 17px;
}

.post__suggest>div {
  display: grid;
  grid-template-columns: minmax(max-content, 40px) 1fr;
  column-gap: 10px;
  row-gap: 16px;
  margin-top: 19px;
}

.post__suggest>div>a:nth-child(odd) div {
  background-color: #0060E1;
  border-radius: 3px;
  border: 0.5px solid #CCCCCC;
  text-align: right;
  font-size: 10px;
  line-height: 11.5px;
  padding: 6px 6px 6px 7px;
  color: white;
  border-radius: 3px;
}

.react__wrapper {}

.react__images {}

.react__images.collapsed {
  margin-right: 0;
}

.react__images-footer {
  font-size: 18px;
  line-height: 21px;
}

.react__slider {
  min-height: 500px;
}

.react__slider-btn {
  width: 50px;
  height: 50px;
}

.react__slider-left,
.react__slider-right {}

.react__comments-wrapper {
  flex: 0 0 436px;
  margin-right: 0;
}

.react__comments-wrapper.collapsed {
  margin-right: -436px;
}

.react__comments-container {}

.react__comments-header {
  color: var(--gray-300);
}

.react__comment-title {
  color: var(--gray-500);
}

.react__comment-content {
  color: var(--gray-400);
}

.react__replay [contenteditable][placeholder]:empty:before {
  content: attr(placeholder);
  position: absolute;
  color: gray;
  background-color: transparent;
}

.react__replay {
  max-height: 300px;
  /* overflow-y: auto; */
}

/* Comment page */
.comment__header {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  z-index: 10;
}

.comment__wrapper {
  background-color: #F0F0F0;
}

.comment__header-main,
.comment__container {
  max-width: 680px;
}

.comment__container {
  margin: 0 auto;
  background-color: white;
}

/* __________ IMAGE DETAIL _____________ */
button.media__btn,
button.media__btn:disabled {
  background-color: transparent;
}